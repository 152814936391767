<template>
    <b-container>
        <b-row>
            <b-col cols="12" md="12" lg="6">
                <h1 class="font-weight-bold text-center text-lg-left mb-2 mt-lg-5 mb-lg-3">
                    Регистрация<br>бизнеса онлайн
                </h1>
                <div class="text text-center text-lg-left mb-4 pb-lg-2">
                    Мы поможем подготовить документы для подачи в исполком.<br>
                    Для этого потребуется не более 10 минут.
                </div>
                <u-button
                        variant="u-button"
                        text="Как это работает?"
                        size="medium"
                        class-button="d-none d-lg-block"
                        type="button"
                        v-scroll-to="{ el: '#scroll-from-header-banner', offset: -50}"
                />
            </b-col>
            <b-col cols="12" md="6" lg="6" offset-md="3" offset-lg="0">
                <u-img-blockquote col-classes="col-12 col-md-12"
                                  blockquote-classes="bottom"
                                  img-src="/img/header-banner-2.png"
                                  img-alt="Регистрация бизнеса онлайн"
                                  img-blockquote="<b> * Обратите внимание:</b>
                        сервис носит справочно-информационный характер и не
                        предполагает ответственности юриста за ваш выбор"
                />
            </b-col>
            <b-col cols="12">
                <u-button variant="u-button"
                          text="Как это работает?"
                          size="medium"
                          class-button="d-block d-lg-none mx-auto mt-5"
                          type="button"
                          v-scroll-to="{ el: '#scroll-from-header-banner'}"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Banner",
        components: {
            UButton: () => import(/* webpackChunkName: "u-button-in-banner"*/'../UButton'),
            UImgBlockquote: () => import(/* webpackChunkName: 'u-img-blockquote'*/'../UImgBlockquote'),
        },
    }
</script>